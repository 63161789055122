/* @import url(http://fonts.googleapis.com/css?family=Vollkorn:400,400italic,700,700italic&subset=latin); */
@import url(http://fonts.googleapis.com/css?family=Roboto:400,400italic,700,700italic&subset=latin);



.documentation article {
    line-height: 1.6em;
    margin: auto;
    padding: 0 0 3em 0;
    /* padding: 0 12px 3em 12px; */
    font-size: 12pt;
    font-family: Roboto;
    /* border: 1px solid #fafafa; */
    /* box-shadow: 0 0 3px black; */
    /* background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 1%, rgba(255,255,255,1) 1%, rgba(255,255,255,1) 99%, rgba(0,0,0,1) 99%, rgba(255,255,255,0) 100%); */
    background-position: 323px;
    background-repeat: no-repeat;
    /* background: #fefefe; */
}

@media screen and (min-width: 1024px) {
    .documentation {
        padding: 0 36px 3em 0;
        /* compensates for the internal padding of the left menu */
    }
}



.documentation table {
    border-spacing: 0;
}

@media screen and (max-width: 1024px) {


    .documentation table {
        max-width: 100%;
        overflow-x: auto;
        display: block;

    }
}

.documentation table tr {
    overflow: scroll;
}

.documentation table th {
    /* font-size: 0.9em; */
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    background-color: #fafafa;
    padding: 6px;
}

.documentation table td {
    border-bottom: 1px solid darkgrey;
    margin: 0;
    padding: 12px 6px;
    /* font-size: 0.9em; */
    font-family: sans-serif;
    text-align: left;
}

@media screen and (min-width: 1024px) {
    /* .documentation {
        padding-bottom: 3em;
    } */
}

.documentation article {
    font-size: 14px;
    font-family: "Roboto", "Vollkorn", "Palatino", Times;
    color: #333;
    line-height: 1.4;
    text-align: justify;
}

.documentation article p,
.documentation article ul li,
.documentation article ol li {
    font-family: "Roboto", Helvetica, sans-serif;
    line-height: 1.6em;
    font-size: 16px;

    /* font-size: 0.9em; */
}

.documentation header,
/* .documentation .nav, */
.documentation article,
.documentation footer {
    /* width: 700px; */
    margin: 0 auto;
}

.documentation .nav {
    margin-right: 24px;
}

.documentation article {
    margin-bottom: 4em;
    min-height: 400px;
    margin-top: 2em;
}

@media screen and (min-width: 1024px) {
    .documentation article {
        margin-top: 0;
    }
}

.documentation footer {
    margin-bottom: 50px;
}

.documentation video {
    margin: 2em 0;
    border: 1px solid #ddd;
}

/* .documentation .nav {
    padding: 1em 0;
    line-height: 1.6;
}

.documentation .nav p {
    margin: 0;
} */

/* Typography
-------------------------------------------------------- */

.documentation h1 {
    margin-top: 0;
    font-size: 2.7em !important;
    font-weight: normal;
    text-align: left;
}

.documentation h2 {
    font-weight: bold;
    font-size: 1.8em !important;
    text-align: left;
    margin-top: 2em;
}

.documentation h3 {
    font-size: 1.5em !important;
    font-weight: bold;
    /* font-style: italic; */
    margin-top: 3em;
}

.documentation p {
    margin-top: 0;
    -webkit-hypens: auto;
    -moz-hypens: auto;
    hyphens: auto;
}

@counter-style docs-semaphore-counter-style {
    system: fixed;
    symbols: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Yng9Imh0dHBzOi8vYm94eS1zdmcuY29tIj4gPGRlZnM+PC9kZWZzPiA8ZWxsaXBzZSBzdHlsZT0ic3Ryb2tlOiByZ2IoMjU1LCAxNDAsIDApOyBzdHJva2Utd2lkdGg6IDRweDsgcGFpbnQtb3JkZXI6IGZpbGw7IGZpbGw6IG5vbmU7IiBjeD0iMTIiIGN5PSIxMiIgcng9IjYiIHJ5PSI2IiBieDpvcmlnaW49IjAuNSAwLjUiPjwvZWxsaXBzZT4gPC9zdmc+Cg==);
    suffix: " ";
}

.documentation article ul {
    padding-left: 1em;
    margin-bottom: 2em;
}

.documentation article ul li {
    /* system: fixed; */
    list-style: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Yng9Imh0dHBzOi8vYm94eS1zdmcuY29tIj4gPGRlZnM+PC9kZWZzPiA8ZWxsaXBzZSBzdHlsZT0ic3Ryb2tlOiByZ2IoMjU1LCAxNDAsIDApOyBzdHJva2Utd2lkdGg6IDRweDsgcGFpbnQtb3JkZXI6IGZpbGw7IGZpbGw6IG5vbmU7IiBjeD0iMTIiIGN5PSIxMiIgcng9IjYiIHJ5PSI2IiBieDpvcmlnaW49IjAuNSAwLjUiPjwvZWxsaXBzZT4gPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-position: 0 0.32em;
    background-size: 1.1em;
    /* list-style: docs-semaphore-counter-style; */
    padding-left: 1.5em;
    margin-bottom: 0.5em;
}

.documentation article .aui-root ul li {
    list-style: none !important;
    background-image: none;
    padding-left: 0;
}

.documentation article ol {
    padding-left: 1.2em;
}

.documentation article blockquote {
    margin-left: 1em;
    padding-left: 1em;
    border-left: 1px solid #ddd;
}

.documentation code {
    display: inline;
    font-family: "Consolas", "Menlo", "Monaco", monospace, serif;
    font-size: .8em;
    background: white;
    padding: 3px;
    text-align: left;
}

.documentation a {
    color: #2484c1;
    text-decoration: none;
}

.documentation a:hover {
    text-decoration: underline;
}

.documentation a img {
    border: none;
}

.documentation h1 a,
.documentation h1 a:hover {
    color: #333;
    text-decoration: none;
}

.documentation hr {
    color: #ddd;
    height: 1px;
    margin: 2em 0;
    border-top: solid 1px #ddd;
    border-bottom: none;
    border-left: 0;
    border-right: 0;
}

.documentation p#heart {
    font-size: 2em;
    line-height: 1;
    text-align: center;
    color: #ccc;
}



/* iPad
-------------------------------------------------------- */
@media only screen and (max-device-width: 1024px) {

    .documentation {
        font-size: 120%;
        line-height: 1.4;
    }
}

/* @iPad */

/* iPhone
-------------------------------------------------------- */
@media only screen and (max-device-width: 480px) {

    .documentation {
        text-align: left;
    }

    .documentation article,
    .documentation footer {
        width: auto;
    }

    .documentation article {
        padding: 0 10px;
    }
}

/* @iPhone */

.documentation code {
    background-color: #f0f0f0;
    border-radius: 5px;
}

.ant-image-preview-img {
    background: white;
}

.narrow {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.narrow .ant-card {
    border-radius: 10px;
    background: #fefefe;
    padding: 20px;
    /* box-shadow: 0px 0px 30px lightgrey; */
}

.ant-card .ant-card-body {
    text-align: left;
}

.footer {
    padding-top: 24px;
    text-align: right;
    width: 100%;
}

.footer>* {
    margin-left: 20px;
}

.blog img {
    max-width: 100%;
}

.documentation.blog a.no-underline:hover,
.documentation.blog a.no-underline:active {
    text-decoration: none;
}

.documentation.blog article {
    /* max-width: 840px; */
}

.documentation.blog h1 {
    font-size: 40pt;
    /* font-weight: bold; */
    /* text-align: right; */
}

.documentation.blog h3 {
    font-weight: normal;
}

.documentation.blog {
    font-size: 16px
}


p {
    text-align: justify;
    text-align-last: left;
}

.documentation.blog article ul li .ant-menu-title-content {
    font-size: 1.2em;
}

.documentation.blog article ul li a {
    text-decoration: none;
}

.documentation.blog article ul li {
    list-style: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Yng9Imh0dHBzOi8vYm94eS1zdmcuY29tIj4gPGRlZnM+PC9kZWZzPiA8ZWxsaXBzZSBzdHlsZT0ic3Ryb2tlOiByZ2IoMjU1LCAxNDAsIDApOyBzdHJva2Utd2lkdGg6IDRweDsgcGFpbnQtb3JkZXI6IGZpbGw7IGZpbGw6IG5vbmU7IiBjeD0iMTIiIGN5PSIxMiIgcng9IjYiIHJ5PSI2IiBieDpvcmlnaW49IjAuNSAwLjUiPjwvZWxsaXBzZT4gPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-position: 5px .33em;
    background-size: 1.1em;
    margin-left: 5px;
    /* list-style: docs-semaphore-counter-style; */
    padding-left: 1.5em;
    margin-bottom: 0.5em;
}

.intro-article h2:first-child {
    font-weight: bolder;
}

.blog h3:first-child,
.blog h2:first-child,
.intro-article h2:first-child {
    margin-top: 0;
}

.more-from-the-blog {
    /* background-color: white; */
    border-radius: 8px;
}

.intro-nav-buttons {
    margin-left: auto;
    margin-right: auto;
}

.intro-nav-buttons .ant-space-item {
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
}

.float-right {
    float: right;
    margin-left: 10px;
    /* Adjust the margin as needed */
}

.float-right-clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.more-from-the-blog {
    text-align: left;
    padding-left: 48px;
}
.more-from-the-blog .title {
    margin-bottom: 5px;
}
.more-from-the-blog a.noLinkDecoration:hover,
.more-from-the-blog a.noLinkDecoration:active
{
    text-decoration: none !important;
    text-decoration-style: none !important;
}
@import url('https://fonts.googleapis.com/css?family=Open%20Sans&display=swap');


body {
    margin: 0;
    background: #fffefc;

    /* background: #fff8f0; */
}

.landing-home {
    min-height: 68svh;
}

@media screen and (min-width: 1140px) {
    .landing-home {
        margin-left: auto;
        margin-right: auto;
        /* max-width: 1440px; */
    }
}

.landing-home .ant-typography {
    line-height: 1.6em;
    font-size: 1.5em;
}

.soft-vertical-background {
    background: #fff8f0;

    /* background: white; */
    box-shadow: 0 0 100px 100px #fff8f0;
}

.ant-drawer-mask {
    /* background-color: white;
    border : white; */
}

.ant-drawer {
    /* background: white; */
}

.drawer-toggle {
    border: 0
}

code {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fafafa;
    font-family: monospace;
    white-space: pre-wrap;
    display: block;
}

.docs li {}

.docs ul {
    padding-top: 12px;
    padding-bottom: 12px;
}

.container ul {
    padding: 0;
    /* Remove default padding */
    list-style-type: none;
    /* Remove default bullet points */
}

.docs .ant-typography,
.docs p {
    line-height: 2em;
    font-size: 1.1em;
}

.docs {
    line-height: 2em;
    max-width: 1024px;
    background: white;
    padding: 24px;
}

@media screen and (min-width: 800) {
    .docs {
        padding: 24px;
    }

}

.docs code {
    border-radius: 10px;
    box-shadow: inset 1px 1px 5px;
    text-shadow: 1px 1px lightdarkgrey;
}

.ant-layout {
    background: none;
}

/* .ant-layout-content {
    padding: 0;
} */
.ant-layout-header {
    background: transparent;
    color: black;
    padding: 0 15px;
}

.ant-menu-light {
    background-color: transparent;
}

/* .parallax::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px) scale(1.5);
    background-size: 100%;
    z-index: -1000;
  } */
@keyframes changeColor {

    0% {
        border-color: darkorange;
        /* rotate: 90deg; */
        box-shadow: inset 30px 30px 30px darkgrey, 30px 30px 30px darkgrey;
    }

    25% {
        border-color: darkgreen;
        /* rotate: 180deg; */
        box-shadow: inset 30px 30px 30px darkgrey, 30px 30px 30px darkgrey;
    }

    50% {
        border-color: darkred;
        /* rotate: 270deg; */
        box-shadow: inset 30px 30px 30px darkgrey, 30px 30px 30px darkgrey;
    }

    75% {
        border-color: darkgreen;
        border-image-width: 300px;
        border-image-source: linear(circle, rgba(139, 0, 0, 1) 0%, rgba(139, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
        /* rotate: 360deg; */
        box-shadow: inset 30px 30px 30px darkgrey, 30px 30px 30px darkgrey;
    }

    100% {
        border-color: darkorange;
        box-shadow: inset 30px 30px 30px darkgrey, 30px 30px 30px darkgrey;
    }
}

@keyframes rotate {
    0% {
        /* border-image-source: linear(circle, rgba(139,0,0,1) 0%, rgba(139,0,0,1) 0%, rgba(255,255,255,0) 100%); */
        rotate: 0deg;
    }

    25% {
        rotate: 90deg;
    }

    50% {
        rotate: 180deg;
    }

    75% {
        rotate: 270deg;
    }

    100% {
        rotate: 360deg;
    }
}

.affix-hidden,
.ant-affix div {
    transition: all 1s;
}

.ant-affix {
    z-index: -1000;
}

.ant-affix .affix-hidden {
    opacity: 0;
}

.ant-affix div {
    opacity: .4;
}

.semaphore-logo-static {
    right: 0;
    position: relative;
    border-radius: max(100vh, 100vw);
    border: 18px darkorange solid;
    padding: 18px;

    overflow: visible;
    aspect-ratio: 1;
    /* margin-left: auto;
    margin-right: auto; */

    /* transform: rotate(90deg); */
    /* z-index: -1000; */

}


.semaphore-logo {
    right: 0;
    position: relative;
    border-radius: max(100vh, 100vw);
    border: 18px darkred solid;
    padding: 18px;
    /* padding: min(min(48px, 5vw), 5vh);
    border: min(min(48px, 5vw), 5vh) darkred solid; */
    color: darkred;
    animation-name: changeColor;
    animation-iteration-count: infinite;
    animation-duration: 200s;
    transition-timing-function: ease-in-out;
    box-shadow: inset 30px 30px 30px black;
    overflow: visible;
    aspect-ratio: 1;
    /* margin-left: auto;
    margin-right: auto; */

    /* transform: rotate(90deg); */
    /* z-index: -1000; */

}

/* .semaphore-logo-medium {
    border: min(min(36px, 3vw), 3vh) darkred solid;
} */

.semaphore-logo-shadow {
    right: 0;
    position: relative;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-duration: 200s;
    transition-timing-function: ease-in-out;
    box-shadow: inset 30px 30px 30px black;
    overflow: visible;
    z-index: -1000;
}

.semaphore-logo-position {
    position: fixed;
    right: min(50px, 2vw);
    top: min(10vw, 48px);
}

@media screen and (min-width: 960px) {
    .semaphore-logo .semaphore-logo-position {
        left: 10vw;
    }
}


.semaphore-logo-comp {
    border-radius: 80vh;
    top: 0;
    right: 0;
    opacity: .5;
    animation-name: rotate;
    box-shadow: 30px 30px 30px rgba(130, 130, 130, .5);
    /* background-color: green; */
    animation: spin 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
    animation-duration: 200s;
    transition-timing-function: linear;
    z-index: -1000;
}



.ant-card,
.ant-card-body {
    background: none;
}

.ant-card-bordered {
    border: 0;
}

.site-layout {
    /* padding-top: 40px; */
}

.site-layout-background {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

.site-layout-background>div {
    /* background: #fffffe; */
    /* box-shadow: -10px -10px 30px lightgrey, 10px 10px 30px #d0d0d0; fffefc */
    min-height: 90svh;
    padding: 12px;
}

@media screen and (min-width: 720px) {
    .site-layout-background>div {
        padding: 48px;
    }
}

.site-layout-background-landing {
    max-width: 100%;
    /* background: #FF8C0008; */
}

.transparent-block {
    opacity: .5;
    /* background: rgba(255, 255, 255, .3); */
    min-height: max(80vh, 600px);
}

.transparent-block.short {
    min-height: 40vh;
}



.semitransparent-block {
    opacity: .70;
    background: white;
    margin: 50px 0;
    /* padding: 50px 5vh 50px; */
    /* box-shadow: 0px 0vh 10px 10px grey; */
}

.semitransparent-block.high {
    min-height: 98svh;
    padding-top: 10svh;
}

.sides-padding {
    padding: 0 24px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1024px) {
    .semitransparent-block.high {
        padding-top: 30svh;
    }
}

.semitransparent-block.short-top {
    margin-top: 0;
    /* box-shadow: 0px 0vh 100px 100px rgba(255, 255, 255, .3); */
    /* box-shadow: none; */
}


.gradient-bg-white {
    background: linear-gradient(0deg, rgba(255, 0, 0, 0.3) 0%, rgba(0, 255, 0, 1) 13%, rgba(0, 0, 255, 1) 32%, rgba(255, 255, 255, 0.3) 100%);
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 13%, rgba(255, 255, 255, 1) 32%, rgba(255, 255, 255, 0.3) 100%); */
    /* height: 90svh; */
}

@media screen and (min-width: 1024px) {
    .hero-container.gradient-bg-white {
        /* padding: 48px 0 */
    }
}

.semitransparent-block.hero-container>div {
    /* padding-top: 24px; */
    padding-bottom: 48px;
}

.semitransparent-block.short {
    /* padding-bottom: 0; */
    /* bottom: -2vh; */
    /* margin-bottom: 5vh; */
}

.circle-button-card-style {
    display: block;
    padding-top: 40px;
    margin-bottom: 48px;
    text-align: center;
    width: min(200px, 50vw);
    height: min(200px, 50vw);
    border-radius: 100px;
    border: 15px darkorange solid;
    margin-left: 48px;
    margin-right: 48px;
}

.circle-button-card-style:hover {
    border: 15px darkgreen solid;
}

@media screen and (min-width: 1561px) {
    .circle-button-card-style {
        margin-left: 0;
        margin-right: 0;
    }
}


.hero-subtitle {
    font-size: max(1.7vw, 12pt);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    /* margin-top: -10px; */
    font-weight: normal;
    font-style: italic;
    /* margin-bottom: 50px; */
}

.hero-main {
    font-size: 42pt;
    text-align: left;
    padding: 0;
    margin-top: 0;
    margin-bottom: 1em;
}

.hero-explainer {
    font-size: 14pt;
}

.hero-description {
    font-weight: normal;
    padding-top: 0;
    margin-bottom: 0;
    line-height: 1.4;
    /* margin-bottom: 120px; */
    /* margin-bottom: 140px; */
}

.hero-list {
    padding-left: 0;
}

.landing-content-width {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.hero-container {
    /* background-color: white; */
    /* background: #FF8C0008; */

    padding: 0 24px;
    margin-top: 60px;

    min-height: 100svh;
    /* text-align: center; */
    /* max-width: 1440px; */
    /* padding-left: min(50px, 2vw); */
}


.hero-text-container {
    margin: 10vh 0;
}

@media screen and (min-height: 1440px) {
    .hero-text-container {
        min-height: 90svh;
    }
}

.hero {}

@media screen and (min-width: 1024px) {
    .hero {
        /* padding-left: 5vw; */
        /* padding-right: 5vw; */
        text-align: left;
    }

    .hero-text-container {
        min-height: auto;
    }

}

.demo .ant-btn.selected.waiting {
    border: 5px solid darkorange;
}

.demo .ant-btn.selected.working {
    border: 5px solid darkred;
}

.ant-table-caption {
    text-align: left;
    font-size: 1.6em;
    font-weight: bold;
    padding: 8px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 1.5em;
    background-size: 50px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Yng9Imh0dHBzOi8vYm94eS1zdmcuY29tIj4gPGRlZnM+PC9kZWZzPiA8ZWxsaXBzZSBzdHlsZT0ic3Ryb2tlOiByZ2IoMjU1LCAxNDAsIDApOyBzdHJva2Utd2lkdGg6IDRweDsgcGFpbnQtb3JkZXI6IGZpbGw7IGZpbGw6IG5vbmU7IiBjeD0iMTIiIGN5PSIxMiIgcng9IjYiIHJ5PSI2IiBieDpvcmlnaW49IjAuNSAwLjUiPjwvZWxsaXBzZT4gPC9zdmc+Cg==)
}

call-us-selector {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.text-center {
    text-align: center;
}



.crd-card>div {
    text-align: center;
    font-size: min(15pt, 4vw);
}

/* .crd-card .item-icon {
    font-size: 10px;
}
.crd-card .item-text {
    font-size: 20px;
} */
@media screen and (min-width: 576px) {
    .crd-card>div {
        font-size: 30pt;
    }

    .crd-card .item-icon {
        /* font-size: 30pt; */
    }

    .crd-card .item-text {
        text-align: left;
        margin-left: 12px;
        /* font-size: 30pt; */
    }
}

.monaco-editor {
    /* border : inset 10px solid darkblue; */
}

.card-data-table .ant-col {
    padding-bottom: 2em;
}

.hoverable-landing {
    transition: all .3s;
    border-radius: 20px;
    color: black;
    border: 3px solid transparent;
}

.hoverable-landing:hover {
    border: 3px solid darkorange;
    /* color: white; */
}

.hoverable-landing:active {
    border: 3px solid rgb(195, 107, 0);
    color: rgb(195, 107, 0);
    /* color: white; */
}

.landing-block-text {
    font-size: 12pt;
    margin-top: 12px;
    margin-bottom: 48px;
}

.sepia {
    filter: "sepia(.3)";
}

.responsive-reverse-block {
    flex-direction: column-reverse;
}

@media screen and (min-width: 1440px) {
    .responsive-reverse-block {
        flex-direction: row;
    }
}

.semaphore-logo-responsive-float {
    text-align: right;
    max-width: 400px;
    margin-bottom: 36px;
    margin-right: auto;
}

@media screen and (min-width: 1440px) {
    .semaphore-logo-responsive-float {
        display: block-inline;
        float: right;
        margin-left: 48px;
    }
}

/* 
.opblock-summary .opblock-summary-control  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.opblock-summary .opblock-summary-control > .opblock-summary-path {
    font-size: 10pt;
} */

.dygraph-legend {
    left: 60px !important;
    width: 100%;
    opacity: 0.9;
}

.elevated-legend .recharts-default-legend {
    height: 38px;
    /* margin-top: 1em; */
}

.ant-input-group .ant-input-group-addon {
    font-size: .9em;
}

.pre-p {
    white-space: pre-line;
}

@media screen and (max-width: 600px) {
    .global-footer {
        font-size: 0.9em;
    }

    .global-footer a {
        line-height: 3em;
    }
}

.global-footer {
    font-size: 0.9em;
}

.blur-before {
    /* border: 1px solid green; */
    z-index: 11;
}

.blur-before::before {
    content: "";
    position: absolute;
    bottom: -70%;
    left: 0;
    background: inherit;
    /* background: black; */
    background: #fffefc;
    /* opacity: .9; */
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    z-index: -1;
    filter: blur(12px);
    /* backdrop-filter: blur(3px); */
    transform: scale(3) translateY(-10%);
}

.text-right {
    text-align: right
}

.text-left {
    text-align: left
}

.text-italic {
    font-style: italic;
}

.margin-vertical-30 {
    margin-bottom: 30px;
    margin-top: 30px;
}


.border-animation {
    /* height: 100px; */
    width: 50px;
    background: linear-gradient(90deg, black 50%, #fff8f0 50%), linear-gradient(90deg, black 50%, #fff8f0 50%), linear-gradient(0deg, black 50%, #fff8f0 50%), linear-gradient(0deg, black 50%, #fff8f0 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    background-position: 0px 0px, 200px 100px, 0px 100px, 200px 0px;
    /* padding: 10px; */
    animation: border-dance 30s infinite linear;
}

@keyframes border-dance {
    0% {
        background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px;
    }

    100% {
        background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px;
    }
}

.scale-to-container-width-container {
    min-width: 100%;
    /* overflow: visible; */
    /* background-color: green; */
    /* Ensure element maintains its original width */
}


.scale-to-container-width {
    /* transform: scale(.7); */
    transform-origin: 0 0;
    width: 100%;
    /* Set the transform origin to top left corner */
}

div {
    /* transition: 1s all; */
    /* border: 1px solid green; */
}

.blink {
    animation: blink-animation .8s steps(5, start) infinite;
    -webkit-animation: blink-animation .8s steps(5, start) infinite;
    transition: ease-in-out .8;
}

@keyframes blink-animation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.large-slider.ant-slider {
    top: -15px;
}

.large-slider.ant-slider .ant-slider-rail,
.large-slider.ant-slider .ant-slider-track {
    border-radius: 30px;
}

.large-slider.ant-slider .ant-slider-handle::after {
    width: 34px;
    min-height: 34px;
}

.large-font-responsive {
    font-size: 20px;
}

@media screen and (min-width: 800px) {
    .large-font-responsive {
        font-size: 40px;
    }
}
/* @import url(http://fonts.googleapis.com/css?family=Vollkorn:400,400italic,700,700italic&subset=latin); */
@import url(http://fonts.googleapis.com/css?family=Roboto:400,400italic,700,700italic&subset=latin);



.documentation article {
    line-height: 1.6em;
    margin: auto;
    padding: 0 0 3em 0;
    /* padding: 0 12px 3em 12px; */
    font-size: 12pt;
    font-family: Roboto;
    /* border: 1px solid #fafafa; */
    /* box-shadow: 0 0 3px black; */
    /* background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 1%, rgba(255,255,255,1) 1%, rgba(255,255,255,1) 99%, rgba(0,0,0,1) 99%, rgba(255,255,255,0) 100%); */
    background-position: 323px;
    background-repeat: no-repeat;
    /* background: #fefefe; */
}

@media screen and (min-width: 1024px) {
    .documentation {
        padding: 0 36px 3em 0;
        /* compensates for the internal padding of the left menu */
    }
}



.documentation table {
    border-spacing: 0;
}

@media screen and (max-width: 1024px) {


    .documentation table {
        max-width: 100%;
        overflow-x: auto;
        display: block;

    }
}

.documentation table tr {
    overflow: scroll;
}

.documentation table th {
    /* font-size: 0.9em; */
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    background-color: #fafafa;
    padding: 6px;
}

.documentation table td {
    border-bottom: 1px solid darkgrey;
    margin: 0;
    padding: 12px 6px;
    /* font-size: 0.9em; */
    font-family: sans-serif;
    text-align: left;
}

@media screen and (min-width: 1024px) {
    /* .documentation {
        padding-bottom: 3em;
    } */
}

.documentation article {
    font-size: 14px;
    font-family: "Roboto", "Vollkorn", "Palatino", Times;
    color: #333;
    line-height: 1.4;
    text-align: justify;
}

.documentation article p,
.documentation article ul li,
.documentation article ol li {
    font-family: "Roboto", Helvetica, sans-serif;
    line-height: 1.6em;
    font-size: 16px;

    /* font-size: 0.9em; */
}

.documentation header,
/* .documentation .nav, */
.documentation article,
.documentation footer {
    /* width: 700px; */
    margin: 0 auto;
}

.documentation .nav {
    margin-right: 24px;
}

.documentation article {
    margin-bottom: 4em;
    min-height: 400px;
    margin-top: 2em;
}

@media screen and (min-width: 1024px) {
    .documentation article {
        margin-top: 0;
    }
}

.documentation footer {
    margin-bottom: 50px;
}

.documentation video {
    margin: 2em 0;
    border: 1px solid #ddd;
}

/* .documentation .nav {
    padding: 1em 0;
    line-height: 1.6;
}

.documentation .nav p {
    margin: 0;
} */

/* Typography
-------------------------------------------------------- */

.documentation h1 {
    margin-top: 0;
    font-size: 2.7em !important;
    font-weight: normal;
    text-align: left;
}

.documentation h2 {
    font-weight: bold;
    font-size: 1.8em !important;
    text-align: left;
    margin-top: 2em;
}

.documentation h3 {
    font-size: 1.5em !important;
    font-weight: bold;
    /* font-style: italic; */
    margin-top: 3em;
}

.documentation p {
    margin-top: 0;
    -webkit-hypens: auto;
    -moz-hypens: auto;
    -webkit-hyphens: auto;
            hyphens: auto;
}

@counter-style docs-semaphore-counter-style {
    system: fixed;
    symbols: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Yng9Imh0dHBzOi8vYm94eS1zdmcuY29tIj4gPGRlZnM+PC9kZWZzPiA8ZWxsaXBzZSBzdHlsZT0ic3Ryb2tlOiByZ2IoMjU1LCAxNDAsIDApOyBzdHJva2Utd2lkdGg6IDRweDsgcGFpbnQtb3JkZXI6IGZpbGw7IGZpbGw6IG5vbmU7IiBjeD0iMTIiIGN5PSIxMiIgcng9IjYiIHJ5PSI2IiBieDpvcmlnaW49IjAuNSAwLjUiPjwvZWxsaXBzZT4gPC9zdmc+Cg==);
    suffix: " ";
}

.documentation article ul {
    padding-left: 1em;
    margin-bottom: 2em;
}

.documentation article ul li {
    /* system: fixed; */
    list-style: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Yng9Imh0dHBzOi8vYm94eS1zdmcuY29tIj4gPGRlZnM+PC9kZWZzPiA8ZWxsaXBzZSBzdHlsZT0ic3Ryb2tlOiByZ2IoMjU1LCAxNDAsIDApOyBzdHJva2Utd2lkdGg6IDRweDsgcGFpbnQtb3JkZXI6IGZpbGw7IGZpbGw6IG5vbmU7IiBjeD0iMTIiIGN5PSIxMiIgcng9IjYiIHJ5PSI2IiBieDpvcmlnaW49IjAuNSAwLjUiPjwvZWxsaXBzZT4gPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-position: 0 0.32em;
    background-size: 1.1em;
    /* list-style: docs-semaphore-counter-style; */
    padding-left: 1.5em;
    margin-bottom: 0.5em;
}

.documentation article .aui-root ul li {
    list-style: none !important;
    background-image: none;
    padding-left: 0;
}

.documentation article ol {
    padding-left: 1.2em;
}

.documentation article blockquote {
    margin-left: 1em;
    padding-left: 1em;
    border-left: 1px solid #ddd;
}

.documentation code {
    display: inline;
    font-family: "Consolas", "Menlo", "Monaco", monospace, serif;
    font-size: .8em;
    background: white;
    padding: 3px;
    text-align: left;
}

.documentation a {
    color: #2484c1;
    text-decoration: none;
}

.documentation a:hover {
    text-decoration: underline;
}

.documentation a img {
    border: none;
}

.documentation h1 a,
.documentation h1 a:hover {
    color: #333;
    text-decoration: none;
}

.documentation hr {
    color: #ddd;
    height: 1px;
    margin: 2em 0;
    border-top: solid 1px #ddd;
    border-bottom: none;
    border-left: 0;
    border-right: 0;
}

.documentation p#heart {
    font-size: 2em;
    line-height: 1;
    text-align: center;
    color: #ccc;
}



/* iPad
-------------------------------------------------------- */
@media only screen and (max-device-width: 1024px) {

    .documentation {
        font-size: 120%;
        line-height: 1.4;
    }
}

/* @iPad */

/* iPhone
-------------------------------------------------------- */
@media only screen and (max-device-width: 480px) {

    .documentation {
        text-align: left;
    }

    .documentation article,
    .documentation footer {
        width: auto;
    }

    .documentation article {
        padding: 0 10px;
    }
}

/* @iPhone */

.documentation code {
    background-color: #f0f0f0;
    border-radius: 5px;
}

.ant-image-preview-img {
    background: white;
}

.narrow {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.narrow .ant-card {
    border-radius: 10px;
    background: #fefefe;
    padding: 20px;
    /* box-shadow: 0px 0px 30px lightgrey; */
}

.ant-card .ant-card-body {
    text-align: left;
}

.footer {
    padding-top: 24px;
    text-align: right;
    width: 100%;
}

.footer>* {
    margin-left: 20px;
}

.blog img {
    max-width: 100%;
}

.documentation.blog a.no-underline:hover,
.documentation.blog a.no-underline:active {
    text-decoration: none;
}

.documentation.blog article {
    /* max-width: 840px; */
}

.documentation.blog h1 {
    font-size: 40pt;
    /* font-weight: bold; */
    /* text-align: right; */
}

.documentation.blog h3 {
    font-weight: normal;
}

.documentation.blog {
    font-size: 16px
}


p {
    text-align: justify;
    text-align-last: left;
}

.documentation.blog article ul li .ant-menu-title-content {
    font-size: 1.2em;
}

.documentation.blog article ul li a {
    text-decoration: none;
}

.documentation.blog article ul li {
    list-style: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Yng9Imh0dHBzOi8vYm94eS1zdmcuY29tIj4gPGRlZnM+PC9kZWZzPiA8ZWxsaXBzZSBzdHlsZT0ic3Ryb2tlOiByZ2IoMjU1LCAxNDAsIDApOyBzdHJva2Utd2lkdGg6IDRweDsgcGFpbnQtb3JkZXI6IGZpbGw7IGZpbGw6IG5vbmU7IiBjeD0iMTIiIGN5PSIxMiIgcng9IjYiIHJ5PSI2IiBieDpvcmlnaW49IjAuNSAwLjUiPjwvZWxsaXBzZT4gPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-position: 5px .33em;
    background-size: 1.1em;
    margin-left: 5px;
    /* list-style: docs-semaphore-counter-style; */
    padding-left: 1.5em;
    margin-bottom: 0.5em;
}

.intro-article h2:first-child {
    font-weight: bolder;
}

.blog h3:first-child,
.blog h2:first-child,
.intro-article h2:first-child {
    margin-top: 0;
}

.more-from-the-blog {
    /* background-color: white; */
    border-radius: 8px;
}

.intro-nav-buttons {
    margin-left: auto;
    margin-right: auto;
}

.intro-nav-buttons .ant-space-item {
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
}

.float-right {
    float: right;
    margin-left: 10px;
    /* Adjust the margin as needed */
}

.float-right-clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.more-from-the-blog {
    text-align: left;
    padding-left: 48px;
}
.more-from-the-blog .title {
    margin-bottom: 5px;
}
.more-from-the-blog a.noLinkDecoration:hover,
.more-from-the-blog a.noLinkDecoration:active
{
    text-decoration: none !important;
    -webkit-text-decoration-style: none !important;
            text-decoration-style: none !important;
}
